import React from 'react';
import { Link } from "react-router-dom";

import '../styles/pages/home.scss';

import { useState } from 'react';


export default function Home() {

    return (
       <div>
        <h1>Home</h1>
        <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam feugiat, justo quis ullamcorper molestie, est tellus fringilla nisl, nec varius justo orci eget diam. Mauris mattis tincidunt nulla. Integer nec placerat quam. Donec eget elementum lacus. Aliquam id hendrerit erat, congue maximus felis. Ut sit amet aliquam arcu. Phasellus imperdiet mi nec nisl porttitor, eget consectetur est pellentesque. Nam finibus vitae dolor sed dictum. Donec volutpat eget nunc in malesuada. Curabitur tincidunt nisl velit, et volutpat enim congue non. In sit amet rhoncus dolor. Praesent in lacus in sem consequat scelerisque. Aliquam ligula turpis, tristique non consequat quis, aliquet sit amet enim.

Aliquam commodo nisi eu congue tristique. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Sed vel sagittis tellus, ac lobortis dolor. Aenean vehicula massa ac purus blandit, nec vehicula massa lobortis. Praesent eleifend ante nulla, quis pulvinar massa mollis ut. Suspendisse id metus in orci sodales vestibulum at eu risus. Curabitur nec diam mollis, pharetra elit ac, tincidunt erat.

Nam a est pharetra, dapibus metus mattis, ultrices nulla. Aliquam non purus tincidunt eros cursus elementum. Donec lectus odio, vulputate in purus sed, aliquet commodo odio. Aenean vehicula feugiat tellus sed blandit. Sed faucibus nunc a est congue viverra. In aliquam lacus neque, nec dictum quam vulputate sed. Nam sit amet rhoncus mauris.

Curabitur gravida fermentum nulla, non mattis arcu dignissim accumsan. Phasellus scelerisque non nunc ut scelerisque. Ut eget nisi ac quam semper consequat sit amet a justo. Integer id nulla porttitor, maximus magna et, finibus nisi. Aliquam hendrerit, nulla nec iaculis dapibus, orci risus porttitor lectus, eu scelerisque justo enim ac felis. Etiam interdum, elit sed vulputate ultricies, nisl arcu convallis sem, et pellentesque orci est id leo. Proin nec enim mi. Nam ac laoreet erat, non imperdiet elit. Duis non velit mauris. Phasellus interdum quis velit sed interdum. Nam mattis interdum arcu, eget elementum sem mattis ut. Phasellus tincidunt semper dolor, et eleifend leo. Sed sodales justo eros, non dictum ligula lobortis in.

Nullam pellentesque tristique ipsum, eleifend lobortis quam rhoncus at. Aliquam eget diam ante. Curabitur posuere varius velit, et semper arcu bibendum aliquet. Proin mollis consequat dapibus. Aliquam at ipsum lorem. Fusce at nisi ac neque vulputate tempor et ac massa. Morbi dictum massa at lacus malesuada, vel molestie ligula hendrerit. Vestibulum dictum congue tristique. Curabitur vitae metus dolor. Quisque facilisis euismod mollis. Phasellus eu condimentum orci. Morbi quis sapien ac ex hendrerit pharetra quis vel augue. Vivamus nec nibh quis mauris imperdiet sodales pulvinar sit amet turpis. Donec suscipit facilisis neque, at volutpat erat vestibulum eu. Sed at tortor sodales, gravida tortor a, malesuada dui. Ut ac consequat velit.
        </p>
       </div>
    )
}