import React from 'react';
import { Link } from "react-router-dom";

import '../styles/pages/ourTeam.scss';

import { useState } from 'react';


export default function NotFound() {

    return (
       <div>
        <h1>404</h1>
       </div>
    )
}